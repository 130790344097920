<template>
  <v-dialog v-model="state.activate" width="980" scrollable persistent>
    <v-card>
      <v-card-title class="primary--text">
        Responder Convite
      </v-card-title>
      <v-card-text>
        <v-stepper v-model="currentStep">
          <v-stepper-header>
            <template v-for="(step, n) in steps">
              <v-stepper-step
                :key="step.step"
                :complete="currentStep > step.step"
                :step="step.step"
                color="primary"
              >
                {{ step.label }}
              </v-stepper-step>
              <v-divider
                v-if="n < 1"
                :key="`step-divider-${step.step}`"
              ></v-divider>
            </template>
          </v-stepper-header>
        </v-stepper>
        <answer-invite-first-step
          v-if="currentStep === 1"
          @ruleChange="handleRuleChange"
          :state="state"
        />
        <answer-invite-second-step v-if="currentStep === 2" :state="state" />
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="d-flex justify-end align-center">
        <v-btn color="light" @click="handleCancelClick">{{
          btnCancelLabel
        }}</v-btn>
        <v-btn
          :disabled="!canProgress"
          color="primary"
          @click="handleNextStep"
          >{{ btnNextLabel }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import AnswerInviteFirstStep from "@/modules/invited/views/components/AnswerInviteFirstStep";
import AnswerInviteSecondStep from "@/modules/invited/views/components/AnswerInviteSecondStep";
import { InvitedModes } from "@/modules/invited/enums/invited-mode.enum";
import { InviteStatus } from "@/modules/core/enums/invite-status.enum";

import { mapActions } from "vuex";

export default {
  props: {
    state: {
      type: Object,
      required: true
    }
  },
  components: {
    AnswerInviteFirstStep,
    AnswerInviteSecondStep
  },
  data: () => ({
    currentStep: 1,
    steps: [
      {
        step: 1,
        label: "Regulamento(s)"
      },
      {
        step: 2,
        label: "Termo(s) de Adesão"
      }
    ]
  }),
  mounted() {
    this.setInviteMode(this.mountInvitedMode());
  },
  computed: {
    btnCancelLabel() {
      return this.currentStep === 1 ? "Cancelar" : "Voltar";
    },
    btnNextLabel() {
      return this.currentStep === 1 ? "Avançar" : "Fechar";
    },
    canProgress() {
      return this.state.data.acceptRules;
    }
  },
  methods: {
    ...mapActions("invited", ["setInviteMode"]),

    handleNextStep() {
      if (this.currentStep === 2) {
        this.state.activate = false;
      }
      this.currentStep++;
    },

    handleRuleChange(acceptRule) {
      this.state.actions = {
        ...this.state.actions,
        canProgress: acceptRule
      };
    },

    handleCancelClick() {
      if (this.currentStep <= 1) {
        return (this.state.activate = false);
      }
      return this.currentStep--;
    },

    hasBeenInvitedToAbrBci() {
      return this.state.data?.responsavel_convite_abr;
    },

    isSbrhviProgramAccepted() {
      return (
        this.state.data?.status_sbrhvi?.id === InviteStatus.INVITE_ACCEPTED.id
      );
    },

    isSbrhviExceptionAccepted() {
      return this.state?.data?.possui_insencao_sbrhvi;
    },

    isPreviousInvitedInSbrhviProgram() {
      return this.state.data?.responsavel_convite_sbrhvi;
    },

    isInvitedInSbrhviProgram() {
      return (
        this.state?.data?.status_sbrhvi?.id === InviteStatus.INVITE_SENT.id
      );
    },

    isInvitedAbrOnlySbrhviExempt() {
      return (
        (!this.isInvitedInSbrhviProgram() &&
          this.isSbrhviExceptionAccepted()) ||
        this.state?.data?.status_sbrhvi === null
      );
    },

    isInvitedAbrAndHasPrevidousSbrhviResponsible() {
      return (
        this.isSbrhviExceptionAccepted() &&
        this.state?.data?.possui_responsavel_sbrhvi
      );
    },

    mountInvitedMode() {
      if (this.hasBeenInvitedToAbrBci()) {
        if (this.isPreviousInvitedInSbrhviProgram()) {
          return this.isSbrhviProgramAccepted()
            ? InvitedModes.ABRBCI_WITH_SBRHVI_LEADER_ACCEPTED.id
            : InvitedModes.ABRBCI_WITH_SBRHVI_LEADER_UNACCEPTED.id;
        }

        if (this.isInvitedAbrAndHasPrevidousSbrhviResponsible()) {
          return InvitedModes.ABRBCI_WITH_SBRHVI_EXCEPTION_ACCEPTED.id;
        }

        if (this.isInvitedAbrOnlySbrhviExempt()) {
          return InvitedModes.ABRBCI_ONLY_SBRHVI_EXCEPTION_ACCEPTED.id;
        }
        return this.isSbrhviProgramAccepted()
          ? InvitedModes.ABRBCI_ONLY_SBRHVI_LEADER_ACCEPTED.id
          : InvitedModes.ABRBCI_ONLY_SBRHVI_LEADER_UNACCEPTED.id;
      }

      return this.isSbrhviProgramAccepted()
        ? InvitedModes.SBRHVI_ACCEPTED.id
        : InvitedModes.SBRHVI_UNACCEPTED.id;
    }
  }
};
</script>
<style lang="scss" scoped></style>
