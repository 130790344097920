<template>
  <v-data-table
    :hide-default-footer="true"
    :headers="headers"
    :items="responsibles"
    :items-per-page="1000"
    class="bordered-table"
    :no-data-text="$t('text.nenhum_registro_selecionado_tabelas')"
  >
    <template v-slot:[`item.acao`]="{ item }">
      <v-row>
        <v-col cols="5" v-if="item.pode_remover"
          ><v-btn
            small
            text
            color="primary"
            @click="$emit('removeResponsible', item)"
            >Remover</v-btn
          ></v-col
        >
        <v-col cols="7" v-if="item.pode_alterar"
          ><v-btn
            small
            text
            color="primary"
            @click="prepareEditResponsible(item)"
            >Editar dados</v-btn
          ></v-col
        >
      </v-row>

      <dialog-invite-user
        :dialogActivate="stateDialogInviteUser"
        @inviteUser="handleInviteUser"
        @onCloseDialog="handleOnCloseDialog"
      />
    </template>
  </v-data-table>
</template>

<script>
import DialogInviteUser from "@/modules/invite/views/components/DialogInviteUser.vue";
import { AutoWidthCalculator } from "ag-grid-community";

export default {
  name: "TableResponsibles",

  props: {
    responsibles: {
      type: Array,
      required: true
    },

    showPhones: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },

  components: {
    DialogInviteUser
  },

  data: () => ({
    headers: [
      { text: "Nome", value: "nome" },
      { text: "CPF", value: "cpf" },
      { text: "E-mail", value: "email" },
      { text: "Telefone", value: "telefone" },
      { text: "Celular", value: "celular" },
      { text: "", value: "acao" }
    ],
    stateDialogInviteUser: {
      value: false,
      isEdit: true,
      initialData: {}
    }
  }),

  computed: {
    headers() {
      const headersBase = [
        { text: "Nome", value: "nome" },
        { text: "CPF", value: "cpf" },
        { text: "E-mail", value: "email" }
      ];

      const headerAction = { text: "", value: "acao" };

      if (this.showPhones) {
        return [
          ...headersBase,
          { text: "Telefone", value: "telefone" },
          { text: "Celular", value: "celular" },
          headerAction
        ];
      }
      return [...headersBase, headerAction];
    }
  },

  methods: {
    prepareEditResponsible(responsible) {
      this.stateDialogInviteUser.initialData = { ...responsible };
      this.stateDialogInviteUser.value = true;
    },

    async handleInviteUser(payload) {
      this.$emit("editResponsible", payload.data);
      this.stateDialogInviteUser.value = false;
    },

    handleOnCloseDialog() {
      this.stateDialogInviteUser.value = false;
    }
  }
};
</script>

<style lang="scss" scoped></style>
