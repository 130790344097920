<template>
  <div>
    <v-row dense>
      <v-col v-if="statusSBRHVI" cols="6">
        <btn-detail-status
          link=""
          :urlTerm="urlTerm"
          label="SBRHVI"
          :status="statusSBRHVI"
        />
      </v-col>
      <v-col v-if="statusSBRHVI" cols="6">
        <p class="ma-0">
          <span class="secondary-text--text">Safra: </span>
          <span class="primary-text--text">{{ statusSBRHVI.safra }}</span>
        </p>
      </v-col>
      <v-col cols="12" class="mt-5">
        <table-responsibles :responsibles="[responsibleSBRHVI]" />
      </v-col>
    </v-row>
    <v-row dense justify="end" class="mt-5">
      <v-col
        v-if="canSeeUpdateSbrhviResponsible"
        class="mt-2"
        cols="12"
        sm="6"
        md="3"
      >
        <btn-link @click="$emit('update-manager-leader', true)">{{
          $t("text.alterar_responsavel_lider")
        }}</btn-link>
      </v-col>
      <v-col v-if="canSeeUpdateSbrhviResponsible" cols="12" sm="6" md="3">
        <v-btn
          outlined
          block
          color="primary"
          @click="$emit('resend-invite-responsible-sbrhvi', true)"
          >Reenviar Convite</v-btn
        >
        <div v-if="productiveUnity">
          <v-btn
            v-if="productiveUnity.pode_cancelar === false"
            outlined
            block
            color="error"
            class="mt-1"
            @click="$emit('removeSbrHviUser', responsibleSBRHVI)"
            >{{ $t("labels.cancel_invite") }}</v-btn
          >
        </div>
        <div v-else>
          <v-btn
            outlined
            block
            color="error"
            class="mt-1"
            @click="$emit('removeSbrHviUser', responsibleSBRHVI)"
            >{{ $t("labels.cancel_invite") }}</v-btn
          >
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import BtnLink from "@/modules/core/views/components/BtnLink";
import BtnDetailStatus from "@/modules/inviteds/views/components/BtnDetailStatus";
import TableResponsibles from "@/modules/inviteds/views/components/TableResponsibles";

export default {
  props: {
    responsibleSBRHVI: {
      type: String,
      required: true
    },
    urlTerm: {
      type: String,
      required: false
    },
    statusSBRHVI: {
      type: Object,
      required: false
    },
    canSeeUpdateSbrhviResponsible: {
      type: Boolean,
      required: false,
      default: () => false
    },
    productiveUnity: {
      type: Object,
      required: true
    }
  },
  components: {
    BtnLink,
    BtnDetailStatus,
    TableResponsibles
  }
};
</script>
<style lang="scss" scoped></style>
