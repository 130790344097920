<template>
  <div v-if="mode" class="mt-7">
    <v-alert v-if="mode.canSeeAlertAbrBci" class="mt-3" type="info">
      {{ this.$t("text.informativo_safra") }}
    </v-alert>
    <producer-or-group-detail :state="state.data" />
    <p class="ma-0 mt-7 primary-text--text">
      <strong>{{ mode.firstSubtitle }}</strong>
    </p>
    <answer-invite-productive-units-table
      v-if="mode.canSeeProductiveUnitsTable"
      class="mt-5"
      :productiveUnits="state.data.unidades_produtivas"
      @reviewTerm="handleAbrTermReview($event)"
    />
    <p v-if="mode.canSeeSecondSubtitle" class="ma-0 mt-7 primary-text--text">
      <strong>{{ mode.secondSubtitle }}</strong>
    </p>
    <v-row class="mt-4" v-if="mode.canSeeSbrhviRow">
      <v-col cols="5">
        <v-btn
          :disabled="
            mode.btnTermSbrHviDisabled ||
              hasAbrInviteWithStatusSent(state.data.unidades_produtivas)
          "
          outlined
          color="primary"
          @click="handleSbrhvTermReview()"
          >Preencher/Revisar Termo de Adesão</v-btn
        >
      </v-col>
      <v-col
        v-if="state.data && state.data.status_sbrhvi"
        cols="4"
        align-self="center"
      >
        <labelled-status :status="state.data.status_sbrhvi" label="SBRHVI" />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import ProducerOrGroupDetail from "@/modules/inviteds/views/components/ProducerOrGroupDetail";
import AnswerInviteProductiveUnitsTable from "@/modules/invited/views/components/AnswerInviteProductiveUnitsTable";
import LabelledStatus from "@/modules/inviteds/views/components/LabelledStatus";

import { mapActions } from "vuex";
import { InvitedModes } from "@/modules/invited/enums/invited-mode.enum";
import { TermType } from "@/modules/invited/enums/term.enum";
import { InviteStatus } from "@/modules/core/enums/invite-status.enum";

import { mapGetters } from "vuex";

export default {
  props: {
    state: {
      type: Object,
      required: true
    }
  },
  components: {
    ProducerOrGroupDetail,
    AnswerInviteProductiveUnitsTable,
    LabelledStatus
  },
  data: () => ({
    modes: {}
  }),
  mounted() {
    this.modes = {
      [InvitedModes.ABRBCI_WITH_SBRHVI_LEADER_UNACCEPTED
        .id]: this.modeAbrbciAndSbrhviUnaccepted(),
      [InvitedModes.ABRBCI_WITH_SBRHVI_LEADER_ACCEPTED
        .id]: this.modeAbrbciSbrhviAccepted(),
      [InvitedModes.ABRBCI_ONLY_SBRHVI_LEADER_UNACCEPTED
        .id]: this.modeAbrbciAndSbrhviUnaccepted(),
      [InvitedModes.ABRBCI_ONLY_SBRHVI_LEADER_ACCEPTED
        .id]: this.modeAbrbciSbrhviAccepted(),
      [InvitedModes.SBRHVI_ACCEPTED.id]: this.modeSbrhviOnly(),
      [InvitedModes.SBRHVI_UNACCEPTED.id]: this.modeSbrhviOnly(),
      [InvitedModes.ABRBCI_ONLY_SBRHVI_EXCEPTION_ACCEPTED
        .id]: this.modeAbrOnlySbrhviExempt(),
      [InvitedModes.ABRBCI_WITH_SBRHVI_EXCEPTION_ACCEPTED
        .id]: this.modeAbrWithPreviousSbrhviExempt()
    };
  },
  computed: {
    ...mapGetters({
      inviteMode: "invited/getInviteMode"
    }),

    mode() {
      return this.modes[this.inviteMode];
    }
  },
  methods: {
    ...mapActions("invited", ["setInviteDetail"]),

    goToTermsPage() {
      this.$router.push({
        name: "terms"
      });
    },

    handleAbrTermReview(productiveUnit) {
      this.defineInviteDetails(productiveUnit);
      this.goToTermsPage();
    },

    handleSbrhvTermReview() {
      this.defineInviteDetails();
      this.goToTermsPage();
    },

    defineInviteDetails(productiveUnit) {
      this.setInviteDetail({
        produtor_id: this.state.data.produtor_id,
        tipo_produtor: this.state.data.tipo_produtor.id,
        unidade_produtiva_id: productiveUnit?.id ?? null
      });
    },

    hasAbrInviteWithStatusSent(productiveUnits) {
      return productiveUnits.some(
        productiveUnit =>
          productiveUnit.status_abr?.id === InviteStatus.INVITE_SENT.id
      );
    },

    modeAbrbciAndSbrhviUnaccepted() {
      return {
        canSeeAlertAbrBci: true,
        firstSubtitle:
          "1- Confirme o Convite das Unidades Produtivas para os Programas ABR/BCI",
        canSeeProductiveUnitsTable: true,
        canSeeSbrhviRow: true,
        secondSubtitle: "2- Confirme o Convite para o Programa SBRHVI",
        canSeeSecondSubtitle: true,
        btnTermSbrHviDisabled: false
      };
    },

    modeAbrOnlySbrhviExempt() {
      return {
        canSeeAlertAbrBci: true,
        firstSubtitle:
          "1- Confirme o Convite das Unidades Produtivas para os Programas ABR/BCI",
        canSeeProductiveUnitsTable: true,
        canSeeSbrhviRow: false,
        canSeeSecondSubtitle: false,
        btnTermSbrHviDisabled: true
      };
    },

    modeAbrWithPreviousSbrhviExempt() {
      return {
        canSeeAlertAbrBci: true,
        firstSubtitle:
          "1- Confirme o Convite das Unidades Produtivas para os Programas ABR/BCI",
        canSeeProductiveUnitsTable: true,
        canSeeSbrhviRow: false,
        canSeeSecondSubtitle: false,
        btnTermSbrHviDisabled: true
      };
    },

    modeAbrbciSbrhviAccepted() {
      return {
        canSeeAlertAbrBci: true,
        firstSubtitle:
          "1- Confirme o Convite das Unidades Produtivas para os Programas ABR/BCI",
        canSeeProductiveUnitsTable: true,
        canSeeSbrhviRow: true,
        secondSubtitle: "2- Confirme o Convite para o Programa SBRHVI",
        canSeeSecondSubtitle: true,
        btnTermSbrHviDisabled: true
      };
    },

    modeSbrhviOnly() {
      return {
        canSeeAlertAbrBci: false,
        firstSubtitle: "1- Confirme o Convite para o Programa SBRHVI",
        canSeeProductiveUnitsTable: false,
        canSeeSbrhviRow: true,
        secondSubtitle: null,
        canSeeSecondSubtitle: false,
        btnTermSbrHviDisabled: false
      };
    }
  }
};
</script>
<style lang="scss" scoped></style>
