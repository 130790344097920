var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"980","scrollable":"","persistent":""},model:{value:(_vm.state.value),callback:function ($$v) {_vm.$set(_vm.state, "value", $$v)},expression:"state.value"}},[_c('v-card',[_c('v-card-title',{staticClass:"primary--text"},[_vm._v(_vm._s(_vm.$t("labels.manage_invite")))]),_c('v-card-text',[_c('producer-or-group-detail',{staticClass:"my-4",attrs:{"state":_vm.state.managingInvite}}),_c('v-divider'),(_vm.state.managingInvite.unidades_produtivas.length)?_c('h3',{staticClass:"mt-3 primary--text"},[_vm._v(" "+_vm._s(_vm.$t("text.titulo_unidades_produtivas_convidadas_abr_bci"))+" ")]):_vm._e(),(
          _vm.state.managingInvite.unidades_produtivas &&
            _vm.state.managingInvite.unidades_produtivas.length
        )?_c('v-alert',{staticClass:"mt-3",attrs:{"type":"info"}},[_vm._v(" "+_vm._s(this.$t("text.informativo_safra"))+" ")]):_vm._e(),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"col-md-4"},[_c('v-combobox',{attrs:{"label":"Termo de Adesão","items":_vm.termo_files,"variant":"outlined"},on:{"change":function($event){return _vm.openTerm($event)}}})],1)]),_vm._l((_vm.state.managingInvite
          .unidades_produtivas),function(productiveUnity,i){return _c('manage-invites-abr-section',{key:("manage-invites-abr-section-" + i),attrs:{"productiveUnity":productiveUnity,"urlTerm":_vm.files.termo_abr_bci,"statusSBRHVI":_vm.state.managingInvite.status_sbrhvi,"state":_vm.state.managingInvite,"canSeeResendInviteAbrBci":_vm.profile.canSeeResendInviteAbrBci &&
            _vm.canSeeResendInviteAbrBciBySituationInviteSent(productiveUnity),"canSeeRemoveAbrResponsible":_vm.profile.canSeeRemoveAbrResponsible,"canSeeCancelProductiveUnitInvite":_vm.profile.canSeeCancelProductiveUnitInvite},on:{"resendInviteProductiveUnity":function($event){return _vm.handleResendInviteProductiveUnit($event)},"removeProductiveUnity":_vm.handleRemoveProductiveUnity,"removeResponsibleProductiveUnity":_vm.handleRemoveResponsibleProductiveUnity,"updateResponsibleAbrbci":_vm.handleUpdateResponsibleAbrbci}})}),(_vm.state.managingInvite.possui_insencao_sbrhvi)?_c('div',[_c('v-alert',{staticClass:"mt-3",attrs:{"type":"info"}},[_vm._v(" "+_vm._s(_vm.$t("text.producer_with_exempt_sbrhvi"))+" "),_c('br')])],1):_c('div',[_c('h3',{staticClass:"my-4 primary--text"},[_vm._v(" "+_vm._s(_vm.$t("text.titulo_responsavel_lider_sbrhvi"))+" ")]),(_vm.state.managingInvite.unidades_produtivas.length > 0)?_c('div',_vm._l((_vm.state.managingInvite
              .unidades_produtivas),function(productiveUnity,i){return _c('manage-invites-sbrhvi-section',{key:("manage-invites-sbrhvi-section-" + i),attrs:{"productiveUnity":productiveUnity,"urlTerm":_vm.files.termo_sbrhvi,"statusSBRHVI":_vm.state.managingInvite.status_sbrhvi,"responsibleSBRHVI":_vm.state.managingInvite.responsaveis_sbrhvi[0],"canSeeUpdateSbrhviResponsible":_vm.profile.canSeeUpdateSbrhviResponsible && _vm.canResendInviteSbrhvi()},on:{"removeSbrHviUser":_vm.handleRemoveSbrHviUser,"update-manager-leader":_vm.handleUpdateManagerLeader,"resend-invite-responsible-sbrhvi":_vm.handleResendInviteResponsibleSbrhi}})}),1):_c('div',[_c('manage-invites-sbrhvi-section',{key:("manage-invites-sbrhvi-section-" + (0)),attrs:{"urlTerm":_vm.files.termo_sbrhvi,"statusSBRHVI":_vm.state.managingInvite.status_sbrhvi,"responsibleSBRHVI":_vm.state.managingInvite.responsaveis_sbrhvi[0],"canSeeUpdateSbrhviResponsible":_vm.profile.canSeeUpdateSbrhviResponsible && _vm.canResendInviteSbrhvi()},on:{"removeSbrHviUser":_vm.handleRemoveSbrHviUser,"update-manager-leader":_vm.handleUpdateManagerLeader,"resend-invite-responsible-sbrhvi":_vm.handleResendInviteResponsibleSbrhi}})],1),_c('h3',{staticClass:"my-4 primary--text"},[_vm._v(" "+_vm._s(_vm.$t("text.titulo_demais_responsaveis_sbrhvi"))+" ")]),(
            _vm.state.managingInvite &&
              _vm.state.managingInvite.responsaveis_sbrhvi_registrados.length
          )?_c('div',_vm._l((_vm.state.managingInvite
              .responsaveis_sbrhvi_registrados),function(responsavel,index){return _c('manage-invites-sbrhvi-section',{key:("manage-invites-sbrhvi-section-" + index),attrs:{"responsibleSBRHVI":responsavel}})}),1):_c('div',[_c('p',{staticClass:"primary-text--text"},[_vm._v(" "+_vm._s(_vm.$t("text.titulo_nenhum_responsavel_cadastrado"))+" ")])]),_c('dialog-invite-user',{attrs:{"dialogActivate":_vm.dialogInviteUserState},on:{"onCloseDialog":_vm.handleOnCloseDialog,"inviteUser":_vm.handleInviteUser}}),_c('note',{staticClass:"my-5"},[_vm._v(_vm._s(_vm.$t( "text.informativo_alterar_responsavel_sbrhvi_antes_aceite_convite" )))])],1)],2),_c('v-divider'),_c('v-card-actions',{staticClass:"d-flex justify-center align-center"},[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.state.value = false}}},[_vm._v(_vm._s(_vm.$t("labels.close")))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }