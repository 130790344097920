<template>
  <div class="d-flex flex-column">
    <a class="underscore-none" :href="link" target="_blank">
      <div class="float-left">
        <p class="ma-0 label secondary-text--text d-flex">
          <span class="label mr-1">{{ label }}: </span>
          <status :value="status.id">{{ status.descricao }}</status>
        </p>
        <p class="ma-0">
          <span class="secondary-text--text">Data: </span>
          <span class="primary-text--text">{{ data }}</span>
        </p>
      </div>
    </a>
  </div>
</template>
<script>
import Status from "@/modules/inviteds/views/components/Status";

export default {
  components: {
    Status
  },
  props: {
    status: {
      type: Object,
      required: true
    },
    label: {
      type: String,
      required: true
    },

    link: {
      type: String,
      required: true
    },
    urlTerm: {
      type: String,
      required: false
    }
  },
  computed: {
    data() {
      return this.status.data ?? "Não informada";
    }
  },
  methods: {
    openTerm() {
      if (!this.urlTerm) {
        return;
      }
      window.open(this.urlTerm, "_blank");
    }
  }
};
</script>
<style lang="scss" scoped>
.label {
  text-transform: uppercase;
}

.underscore-none {
  text-decoration: none;
}

.icon-style {
  float: left;
  margin-left: 5px;
  color: rgba(0, 0, 0, 0.6);
}

.float-left {
  float: left;
}
</style>
