<template>
  <div v-if="mode" class="mt-7">
    <v-alert v-if="mode.canSeeAlertAbrBci" class="mt-3" type="info">
      {{ this.$t("text.informativo_safra") }}
    </v-alert>
    <producer-or-group-detail :state="state.data" />
    <p class="mt-5 primary-text--text">
      <strong>{{ mode.firstSubtitle }}</strong>
    </p>
    <v-row v-if="mode.canSeeAbriBciRulesBtn">
      <v-col cols="5">
        <v-btn color="primary" @click="handleLgpdAbrBci"
          >Acessar o Regulamento do Programa ABR/BCI</v-btn
        >
      </v-col>
    </v-row>
    <v-row v-if="mode.canSeeSbrhviRulesBtn">
      <v-col cols="5">
        <v-btn color="primary" @click="handleLgpdSbrhvi"
          >Acessar o Regulamento do Programa SBRHVI</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-checkbox v-model="state.data.acceptRules">
          <template slot="label">
            <p class="ma-0 primary-text--text">
              Li e estou de acordo com os
              <strong>{{ mode.acceptRuleLabel }}</strong>
            </p>
          </template>
        </v-checkbox>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import ProducerOrGroupDetail from "@/modules/inviteds/views/components/ProducerOrGroupDetail";
import { InvitedModes } from "@/modules/invited/enums/invited-mode.enum";
import { mapGetters } from "vuex";

export default {
  props: {
    state: {
      type: Object,
      required: true
    }
  },
  components: {
    ProducerOrGroupDetail
  },
  data: () => ({
    acceptRule: false,
    modes: {}
  }),
  computed: {
    ...mapGetters({
      inviteMode: "invited/getInviteMode"
    }),

    mode() {
      return this.modes[this.inviteMode];
    }
  },
  mounted() {
    this.modes = {
      [InvitedModes.ABRBCI_WITH_SBRHVI_LEADER_UNACCEPTED
        .id]: this.modeAbrbciAndSbrhviUnaccepted(),
      [InvitedModes.ABRBCI_WITH_SBRHVI_LEADER_ACCEPTED
        .id]: this.modeAbrbciSbrhviAccepted(),
      [InvitedModes.ABRBCI_ONLY_SBRHVI_LEADER_UNACCEPTED
        .id]: this.modeAbrbciAndSbrhviUnaccepted(),
      [InvitedModes.ABRBCI_ONLY_SBRHVI_LEADER_ACCEPTED
        .id]: this.modeAbrbciSbrhviAccepted(),
      [InvitedModes.SBRHVI_ACCEPTED.id]: this.modeSbrhviOnly(),
      [InvitedModes.SBRHVI_UNACCEPTED.id]: this.modeSbrhviOnly(),
      [InvitedModes.ABRBCI_ONLY_SBRHVI_EXCEPTION_ACCEPTED
        .id]: this.modeAbrOnlySbrhviExempt(),
      [InvitedModes.ABRBCI_WITH_SBRHVI_EXCEPTION_ACCEPTED
        .id]: this.modeAbrWithPreviousSbrhviExempt()
    };
  },
  methods: {
    handleLgpdAbrBci() {
      window.open("/documentos/regulamento_abr.pdf", "_blank");
    },

    handleLgpdSbrhvi() {
      window.open("/documentos/regulamento_sbrhvi.pdf", "_blank");
    },

    modeAbrbciAndSbrhviUnaccepted() {
      return {
        canSeeAlertAbrBci: true,
        firstSubtitle:
          "1- Confirme o aceite aos Regulamentos dos Programas ABR/BCI e SBRHVI",
        canSeeSbrhviRulesBtn: true,
        canSeeAbriBciRulesBtn: true,
        acceptRuleLabel: "Regulamentos dos Programas ABR/BCI e SBRHVI"
      };
    },

    modeAbrbciSbrhviAccepted() {
      return {
        canSeeAlertAbrBci: true,
        firstSubtitle:
          "1- Confirme o aceite aos Regulamentos dos Programas ABR/BCI",
        canSeeSbrhviRulesBtn: false,
        canSeeAbriBciRulesBtn: true,
        acceptRuleLabel: "Regulamentos dos Programas ABR/BCI"
      };
    },

    modeAbrOnlySbrhviExempt() {
      return {
        canSeeAlertAbrBci: true,
        firstSubtitle:
          "1- Confirme o aceite aos Regulamentos dos Programas ABR/BCI",
        canSeeSbrhviRulesBtn: false,
        canSeeAbriBciRulesBtn: true,
        acceptRuleLabel: "Regulamentos dos Programas ABR/BCI"
      };
    },

    modeSbrhviOnly() {
      return {
        canSeeAlertAbrBci: false,
        firstSubtitle:
          "1- Confirme o aceite aos Regulamentos do Programa SBRHVI",
        canSeeSbrhviRulesBtn: true,
        canSeeAbriBciRulesBtn: false,
        acceptRuleLabel: "Regulamentos dos Programas SBRHVI"
      };
    },

    modeAbrWithPreviousSbrhviExempt() {
      return {
        canSeeAlertAbrBci: true,
        firstSubtitle:
          "1- Confirme o aceite aos Regulamentos dos Programas ABR/BCI",
        canSeeSbrhviRulesBtn: false,
        canSeeAbriBciRulesBtn: true,
        acceptRuleLabel: "Regulamentos dos Programas ABR/BCI"
      };
    }
  }
};
</script>
<style lang="scss" scoped></style>
